<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col-12 col-xl-3 order-xl-2 mb-24 sticky">
        <v-card class="overflow-hidden sticky">
          <l-widget>
            <template #header>
              <w-timer
                :start="widget.timer.start"
                :end="widget.timer.end"
                :server-time="widget.timer.current"
              />
            </template>

            <template #default>
              <w-progress
                :progress="widget.progress"
              />
            </template>
          </l-widget>
        </v-card>
      </div>

      <div class="col-12 col-xl-9 order-xl-1">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col">
              <h4>Таблица 1</h4>
            </div>
          </div>
          <div class="row mt-32">
            <div class="col">
              <table-radio
                ref="radioTable"
                :variant-row="variantRow"
                :variant-col="variantCol"
              >
                <template slot="th-first">Утверждения</template>
              </table-radio>
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button @click="onClick">
                Ответить
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { VButton, VCard } from '@/components/base'
import { WTimer, WProgress } from '@components/widgets'
import { LWidget } from '@/layouts'
import { TableRadio } from '@/components/features'

export default {
  name: 'STable',

  components: {
    TableRadio,
    WTimer,
    WProgress,
    LWidget,
    VButton,
    VCard
  },

  data () {
    return {
      widget: {
        progress: {
          total: 3,
          current: 1
        },

        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 120
        }
      },

      variantRow: [
        {
          id: '3',
          text: 'Руководитель моей команды (подразделения) поддерживает предложения сотрудников по оптимизации работы и помогает их внедрению',
          media: '',
          group: '',
          sort: '99999',
          attribute: ''
        },
        {
          id: '4',
          text: ' Я заряжаюсь энергией, обдумывая свои долгосрочные цели',
          media: '',
          group: '',
          sort: '99999',
          attribute: ''
        },
        {
          id: '2',
          text: 'Я чувствую выгорание, постоянную усталость и раздражение от работы',
          media: '',
          group: '',
          sort: '99999',
          attribute: ''
        },
        {
          id: '1',
          text: 'Я могу оптимально организовать свое время исходя из стоящей передо мной задач',
          media: '',
          group: '',
          sort: '99999',
          attribute: ''
        }
      ],

      variantCol: [
        {
          id: '1',
          text: 'Согласен',
          media: '',
          group: '',
          sort: '1',
          attribute: ''
        },
        {
          id: '2',
          text: 'Скорее согласен, чем не согласен',
          media: '',
          group: '',
          sort: '2',
          attribute: ''
        },
        {
          id: '3',
          text: 'Скорее не согласен, чем согласен',
          media: '',
          group: '',
          sort: '3',
          attribute: ''
        },
        {
          id: '4',
          text: 'Не согласен',
          media: '',
          group: '',
          sort: '4',
          attribute: ''
        },
        {
          id: '5',
          text: 'Затрудняюсь ответить',
          media: '',
          group: '',
          sort: '5',
          attribute: ''
        }
      ],

      showTextHint: true
    }
  },

  methods: {
    onClick () {
      console.log(this.$refs.radioTable.getData())
    }
  }
}
</script>
